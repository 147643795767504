<style lang="less" scoped>
  .pfm-default-config-page {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  .toolbox {
    padding: 10px;
    background-color: #FFF;
    border-radius: 5px;
  }
  .query-form {
    margin-top: 10px;
  }
  .table {
    margin-top: 10px;
    height: 0;
    flex: 1;
  }
  .title {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
</style>

<style>
.pfm-default-config-page.app-router-view {
  height: 100%;
}
</style>

<template>
  <div class="pfm-default-config-page">
    <div class="toolbox">
      <div class="title">
        <fm-title :title-text="title"></fm-title>
        <div>
          <Button style="margin-right: 10px;" icon="ios-arrow-back" v-if="back" @click="backClick">返回</Button>
          <Button :loading="loading.load" style="margin-right: 10px;" icon="md-refresh-circle" @click="loadData">刷新</Button>
          <Button icon="md-add-circle" v-if="$authFunsProxyNoRoute['performance.org-unitConfig']" @click="addClick" type="primary">新增</Button>
        </div>
      </div>
      <fm-form class="query-form" inline>
        <fm-form-item style="margin-bottom: 0;" label="标识">
          <fm-input-new clearable v-model="query.valueLabel" />
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;" label="键名">
          <fm-input-new clearable v-model="query.valueKey" />
        </fm-form-item>
        <fm-form-item v-if="type === 'unit'" style="margin-bottom: 0;" label="考核单元">
          <Select filterable clearable v-model="query.pfmUnitId">
            <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.code }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item v-if="type === 'worker'" style="margin-bottom: 0;" label="员工">
          <Select filterable clearable v-model="query.pfmWorkerId">
            <Option v-for="item in workerList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
          </Select>
        </fm-form-item>
      </fm-form>
    </div>
    <div class="table" ref="table">
      <Table :loading="loading.load" v-if="tableHeight !== null" :height="tableHeight" :columns="columns" :data="showDataList">
        <template slot-scope="{ row }" slot="action">
          <Button @click="itemEdit(row)" icon="ios-brush" size="small" type="text">修改</Button>
          <Poptip transfer confirm @on-ok="itemDel(row)" title="确定删除吗？">
            <Button icon="md-trash" size="small" type="text">删除</Button>
          </Poptip>
        </template>
      </Table>
    </div>
    <Drawer :title="formData.id ? '修改' : '新增'" draggable :mask-closable="false" width="30" placement="right" closable v-model="status.form">
      <fm-form label-alone label-align="left">
        <fm-form-item label="月份">
          <DatePicker v-model="formData.month" type="month" placeholder="请输入月份"></DatePicker>
        </fm-form-item>
        <fm-form-item label="考核单元" v-if="type === 'unit'">
          <Select placeholder="请选择考核单元" filterable clearable v-model="formData.pfmUnitId">
            <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.code }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="员工" v-if="type === 'worker'">
          <Select placeholder="请选择员工" filterable clearable v-model="formData.pfmWorkerId">
            <Option v-for="item in workerList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="标识">
          <fm-input-new placeholder="请输入标识" v-model="formData.valueLabel" />
        </fm-form-item>
        <fm-form-item label="键名">
          <fm-input-new placeholder="请输入键名" v-model="formData.valueKey" />
        </fm-form-item>
        <fm-form-item label="默认值">
          <fm-input-new placeholder="请输入默认值" v-model="formData.value" />
        </fm-form-item>
        <fm-form-item label="类型">
          <RadioGroup v-model="formData.type">
            <Radio v-for="item in typeList" :key="item.value" :label="item.value">{{item.label}}</Radio>
          </RadioGroup>
        </fm-form-item>
        <fm-form-item label="公式" v-if="formData.type === 'formula'">
          <fm-input-new placeholder="请输入默认值" v-model="formData.formula" />
        </fm-form-item>
        <fm-form-item label="状态">
          <RadioGroup v-model="formData.status">
            <Radio v-for="item in statusList" :key="item.value" :label="item.value">{{item.label}}</Radio>
          </RadioGroup>
        </fm-form-item>
        <fm-form-item label="">
          <div style="display: flex;">
            <Button :loading="loading.submit" style="flex: 1;" @click="status.form = false" type="default">取消</Button>
            <Button :loading="loading.submit" style="margin-left: 10px;flex: 1;" @click="submit" type="info">确定{{(formData.id ? '修改' : '新增')}}</Button>
          </div>
        </fm-form-item>
      </fm-form>
    </Drawer>
  </div>
</template>

<script>
import { request } from '@/api'

function getDefaultQuery () {
  return {
    name: null,
    pfmUnitId: null,
    pfmWorkerId: null
  }
}

function getDefaultFormData () {
  return {
    id: null,
    month: null,
    pfmUnitId: null,
    valueKey: null,
    valueLabel: null,
    value: null,
    type: 'sys',
    formula: null,
    status: 'enable',
  }
}

export default {
  props: {
    type: { type: String, default: 'unit' }
  },
  data () {
    return {
      dataList: [],
      workerList: [],
      query: getDefaultQuery(),
      formData: getDefaultFormData(),
      status: {
        form: false
      },
      loading: {
        load: false,
        submit: false
      },
      tableHeight: null
    }
  },
  computed: {
    title () {
      if (this.type === 'unit') {
        return '考核单元明细默认配置'
      } else if (this.type === 'worker') {
        return '员工月度明细默认配置'
      } else {
        return '错误'
      }
    },
    api () {
      if (this.type === 'unit') {
        return 'pfm_unit_month_detail_default'
      } else if (this.type === 'worker') {
        return 'pfm_worker_month_detail_default'
      } else {
        return '错误'
      }
    },
    back () {
      return this.$route.query.back
    },
    typeList () {
      return [
        { value: 'sys', label: '系统计算' },
        { value: 'input', label: '用户录入' },
        { value: 'formula', label: '公式计算'  }
      ]
    },
    statusList () {
      return [
        { value: 'enable', label: '启用', tag: 'blue' },
        { value: 'void', label: '无效', tag: 'default' },
        { value: 'not_enable', label: '未启用', tag: 'red' }
      ]
    },
    unitList () {
      return this.$store.getters.pfmUnitList
    },
    columns () {
      const statusList = this.statusList
      const typeList = this.typeList
      const type = this.type

      const columns = [
        { title: '标识', key: 'valueLabel' },
        { title: '键名', key: 'valueKey' },
        { title: '默认值', key: 'value' },
        {
          title: '类型', key: 'type', render: (h, { row }) => {
            const type = typeList.find(v => v.value === row.type) || { label: '其他' }
            return h('div', type.label)
          }, filters: typeList, filterMethod: (value, row) => {
            return row.type === value
          }
        },
        {
          title: '状态', key: 'status', render: (h, { row }) => {
            const status = statusList.find(v => v.value === row.status) || { label: '其他', tag: 'default' }
            return h('Tag', {
              props: {
                color: status.tag,
              }
            }, status.label)
          }, filters: statusList, filterMethod: (value, row) => {
            return row.status === value
          }
        },
        { title: '月份', key: 'month' },
        type === 'unit' ? { title: '考核单元', key: 'pfmUnitName' } : { title: '员工', key: 'pfmWorkerName' },
        { title: '操作', key: 'action', slot: 'action', width: 170 }
      ]

      return columns
    },
    showDataList () {
      const query = this.query
      const match = Object.keys(query).filter(v => query[v]).map(v => {
        return { key: v, value: query[v] }
      })
      return match.length > 0 ? this.dataList.filter(item => {
        return match.every(v => {
          if (typeof v.value !== 'string') {
            return item[v.key] === v.value
          } else {
            return String(item[v.key]).indexOf(v.value) > -1
          }
        })
      }) : this.dataList
    }
  },
  methods: {
    transData (data) {
      return JSON.parse(JSON.stringify(data, (key, value) => {
        if ((['month'].includes(key) || value instanceof Date) && value) {
          return this.$datetime.format(value, 'Y-M-D H:I:S')
        }
        return value
      }))
    },
    backClick () {
      this.$router.back()
    },
    itemEdit (item) {
      this.formData = JSON.parse(JSON.stringify(item))
      this.status.form = true
    },
    async itemDel (item) {
      await request('/performance/' + this.api + '/' + item.id, 'delete')
      this.$Message.success('已删除')
      this.loadData()
    },
    addClick () {
      this.formData = getDefaultFormData()
      this.status.form = true
    },
    async loadData () {
      if (this.$authFunsProxyNoRoute['performance.org-unitConfig']) {
        this.loading.load = true
        this.dataList = await request('/performance/' + this.api, 'GET')
        this.loading.load = false
      }
    },
    resize () {
      this.tableHeight = this.$refs.table.offsetHeight
    },
    async submit () {
      this.loading.submit = true
      try {
        if (this.formData.id) {
          await request('/performance/' + this.api + '/' + this.formData.id, 'put', this.transData(this.formData))
        } else {
          await request('/performance/' + this.api, 'post', this.transData(this.formData))
        }
        this.status.form = false
        this.loading.submit = false
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.loading.submit = false
      }
    },
    async loadWorkerList () {
      this.workerList = await request('/performance/pfm_worker', 'get')
    }
  },
  mounted() {
    this.loadData()
    if (this.type === 'unit') {
      this.$store.dispatch('loadPfmUnitList')
    } else {
      this.loadWorkerList()
    }
    window.addEventListener('resize', this.resize)
    this.resize()
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
  },
}
</script>